body {
  font-family: halcom, system-ui,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
  font-size: 16px;
}

html, body {
  overflow-x: hidden;
}

.p {
  font-size: 18px;
}

.v-logo {
  float: left;
}

.nav-sign-up-button {
  font-size: 1.2rem;
}

.vv-nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
  color: black !important;
}

.vv-nav-link:hover {
  border-bottom: 4px solid #013950;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-header {
  padding-left: 20px;
  /* box-shadow: 0px 2px 5px #f0efee; */
}

.green-nav {
  background-color: #DDF8E2;
}

.blue-nav {
  background-color: #013950;
}

.banner-row {
  padding: 0;
}

.home-top-text {
  margin-bottom: 50px;
  letter-spacing: 1px;
  font-size: 1.3rem;
}

.value-prop-column {
  padding-left: 10%;
}

.blogpost-card {
  margin-top: 10px;
  color: black;
  border-radius: 25px;
  border: 1px solid black;
}

.posting-links {
  color: #013950;
  margin: 5px;
}

.angellist-icon {
  margin-left: 5px;
}

.company-card-image {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  width: 40%;
  box-shadow: 5px 5px 5px #999;
  background-color: white;
}

.company-search-title {
  font-size: 1.75rem;
}

.new-button {
  background-color: #F59070;
}

.company-card-col {
  margin-top: 40px;
}

.category-button {
  background-color: #013950;
  border-color: #013950;
  box-shadow: 2px 2px 2px #999;
}

.category-button:hover, .category-button:active, .category-button:focus {
  color: #ffffff !important;
  background-color: #F59070 !important;
  border-color: #F59070 !important;
}

.job-category-button {
  background-color: #013950;
  border-color: #013950;
  box-shadow: 2px 2px 2px #999;
}

.job-category-button:hover, .job-category-button:active, .job-category-button:focus {
  color: black !important;
  background-color: #ffb3ff !important;
  border-color: #ffb3ff !important;
}

.checkit-button {
  display: block;
  width: 150px;
  margin: 0 auto;
  margin-top: 20px;
}

.thanks-copy {
  /* padding: 30px; */
  margin-left: 25px;
  margin-right: 50px;
  /* border: 1px solid rgba(0,0,0,.125); 
  border-radius: 25px; */
}

.opportunity-button {
  margin-top: 20px;
  margin-left: 10px;
}

.company-card {
  border-radius: 25px;
}

.card-img-top {
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.blogcard-col {
  padding-bottom: 25px;
}

.about-row {
  padding-left: 2%;
  margin-top: 0px;
}

.about-col {
  /* margin-top: 50px; */
  width: 100%;
}

.about-img {
  width: 100%;
  box-shadow: 14px 14px 60px -10px rgb(0 0 0 / 40%);
}

.amy-intro-header {
  font-size: 3rem;
  margin-top: -20px;
  color: #999;
}

.hey-there-header {
  font-size: 1.2rem;
  font-style: italic;
}

.impact-tag {
  border-radius: 25px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  color: white;
  width: fit-content;
  margin-bottom: 2px;
  margin-top: 2px;
  box-shadow: 2px 2px 2px #999;
}

.impact-category-tag {
  /* border: 1px solid #FCD947; */
  background-color: #013950;
  /* color: black; */
}

.impact-subcategory-tag {
  /* border: 1px solid #F59070; */
  background-color: #F59070;
  /* color: black; */
}

.dropdown-div {
  margin-left: 10px;
  margin-top: 30px;
}

.category-dropdown-div{
  display: initial;
  margin-right: 20px;
}

.subcategory-dropdown {
  background-color: #013950;
  border-color: #013950;
  box-shadow: 2px 2px 2px #999;
}

.subcategory-dropdown:hover, .subcategory-dropdown:active, .subcategory-dropdown:focus {
  color: #ffffff !important;
  background-color: #F59070 !important;
  border-color: #F59070 !important;
}

.blogpost-title {
  font-size: 2.5rem;
  padding-top: 100px;
  text-align: center;
}

.blogpost-proposition {
  text-align: center;
  padding-left: 25%;
  padding-right: 25%;
}

@media (max-width: 600px) {
  .blogpost-proposition {
    padding-left: 10%;
    padding-right: 10%;
  }
}

.blogpost-div {
  padding: 50px 50px 200px 50px;
}

@media (max-width: 600px) {
  .blogpost-div {
    padding: 20px 20px 100px 20px;
  }
}

.spinners {
  margin-top: 20px;
  text-align: center;
}

.investment-item {
  margin-bottom: 0;
  margin-left: 15px;
}

.investments-list {
  size: 12px;
}

.social-logos-header-item {
  margin-left: 5px;
  margin-right: 5px;
  fill: black;
}

.social-logos-header-item:hover {
  fill: #C6ADFF;
}

.social-logos-header {
  float: right;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 25px;
}

.social-logos-header {
    display:none !important;
    /* margin: 0 auto;
    padding-right: 15px; */
}
.new-feature-banner {
  width: 90% !important;
}

.social-logos-footer {
  display: block !important;
  text-align: center;
  margin: 0 auto;
}

.new-feature-banner {
  border-radius: 25px;
  box-shadow: 5px 5px 5px #999;
  border: 1px solid rgba(0,0,0,.125);
  width: 60%;
  margin: 0 auto;
  display: block;
}

.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #013950;
  color: white;
  text-align: center;
}

.feedback-header {
  font-size: 22px;
}

.blogpost-container {
  max-width: 740px;
  margin: 0 auto;
  display: block;
  margin-bottom: 100px;
}

.blogpost-header {
  font-size: 50px;
}

.blogpost-header-img {
  box-shadow: 5px 5px 5px #999;
  border-radius: 25px;
  width: 100%;
  margin-bottom: 30px;
}

.blogpost-image-col {
  margin: 0 auto;
  display: block;
}

@media (max-width: 800px) {
  .blogpost-container {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  
  .blogpost-img {
    margin-top: 20px !important;
  }

  .blogpost-img-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .img-caption {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.blogpost-subheader {
  font-weight: 200;
}

.blogpost-header-container {
  margin-bottom: 30px;
}

.blogpost-date-author-item {
  font-weight: 300;
  margin-bottom: 0px;
}

.breakpoint-dots {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 24px;
}

.blog-quote {
  font-weight: 200;
  margin-left: 50px;
  margin-right: 50px;
  text-align: right;
  margin-top: 30px;
  margin-bottom: 30px;
  color: grey;
  font-style: italic;
}

.interview-question {
  margin-bottom: 30px;
}

.interview-question-q {
  font-size: 24px;
}

.blogpost-img {
  width: 100%;
}

.blogpost-img-row {
  margin-left: -80px;
  margin-right: -80px;
}

.img-caption {
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
  margin-top:10px;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 14px;
}

.large-img-caption {
  margin-left: 130px;
  margin-right: 130px;
}

.blogpost-custom-video {
  width: 100%;
  object-fit: cover;
}

.blog-link {
  color: #013950;
  text-decoration: underline;
}

.center-img-div {
  margin: 0 auto;
}

.blogpost-video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.blogpost-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dropdown-top-item {
  font-weight: bold
}

.waitlist-signup-input{
  margin-left: 10%;
  margin-right: 10%;
  font-size: 18px;
}

.find-job-header {
  padding-top: 100px;
  text-align: center;
  font-size: 3.5rem;
}

.find-job-subheader {
  text-align: center;
  font-weight: 300;
}

.sign-up-button {
  margin-left:20px;
  color: white;
  height: 100%;
}

.signup-group {
  max-width: 485px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 40px;
  text-align: center;
}

.signup-input {
  border-radius: .25rem !important;
  font-size: 18px !important;
}

@media (max-width: 700px) {
  .sign-up-button {
    /* width: 395px; */
    margin-top: 10px !important;
    margin-left: 0px !important;
    flex: 0 0 100%;
  }

  .signup-btn-div {
    flex: 0 0 100%;
  }
}

.top-10-company-title {
  padding-top: 20px;
  font-size: 24px;
}

.top-10-company-logo {
  width: 20%;
  border-radius: 20%;
}

@media (max-width: 600px) {
  .top-10-company-logo {
    width: 40%;
    display: block;
    margin: 0 auto;
  }
}

.article-src-text {
  font-size: 12px;
}

.buymeacoffee-img{
  width: 50%;
  margin: 0 auto;
}

.author-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.author-header {
  display: flex;
  margin-top: 20px;
}

.blogpost-date-author {
  margin-left: 8px;
}

.company-entry-count {
  margin-top: 10px;
  margin-left: 10px;
}

.jobseeker_image {
  margin-right: auto;
  margin-left: auto;
  margin-top: 100px;
  display: block;
  width: 60%;
}

.working-woman-div {
  margin-top: 70px;
  background: #013950;
}

.working-woman-img {
  width: 50%;
}

.category_icons {
  margin-right: auto;
  margin-left: auto;
  display: block;
  width: 60%;
}

.category_icon_div {
  background: #013950;
  padding-top: 30px;
  margin-top: 100px;
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-radius: 25px;
}

.category_choice {
    color: white;
    text-align: center;
    font-size: 24px;
}

.logo-text-div {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.logo-text {
  font-size: 1.5rem;
}

.logo-div {
  height: 50px;
}

.section-1 {
  height: 700px;
  padding-top: 70px;
  background-color: #DDF8E2;
}

.section-2 {
  background-color: white;
  padding-top: 200px;
  padding-bottom: 200px;
}

.section-3 {
  background-color: #013950;
}

.ppp-img {
  height: 450px;
}

@media (max-width: 600px) {
  .ppp-img {
    height: 350px;
    padding-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.generic-link {
  color: black;
}

.generic-link:hover {
  color: #C6ADFF;
}

.blog-card-link {
  color: black;
}

.blog-card-link:hover {
  color: #C6ADFF;
}

.greendrop-page {
  background-color: #DDF8E2;
  min-height: 800px;
  padding-top: 50px;
}

.bluedrop-page {
  background-color: #013950;
  min-height: 800px;
  padding-top: 50px;
}

.whitedrop-page {
  min-height: 800px;
  padding-top: 50px;
}

.job-item {
  margin-top: 20px;
}

.job-item p {
  margin-top: 0;
  margin-bottom: 0;
}

.job-category-item {
  margin-bottom: 50px;
}

.job-category-title {
  font-size: 1.2rem;
  font-family: halcom,system-ui,sans-serif;
  line-height: 1.05;
  color: black;
  font-weight: 300;
  text-decoration: underline;
  text-decoration-color: #ffff33;
  text-underline-position: unset;
  text-underline-offset: -5px;
  text-decoration-thickness: 12px;
  text-decoration-skip-ink: none;
}

.job-board-div {
  margin-left: 50px;
  margin-right: 50px;
  padding-top: 20px;
  height: 60vh;
  overflow: scroll;
}

.select-pill input {
  visibility: hidden;
  width: 0px;
}

.select-pill {
  border-radius: 10px;
  color: black;
  margin-right: 5px;
}

.category-pill-group {
  margin: 50px;
}

.pill-icon {
  margin-left: 5px;
}

.role-category-pill {
  border-color: #ffb3ff !important;
}

.role-category-pill .pill-icon {
  color: #ffb3ff;
}

.jobs-pill-checkbox:not(:checked) ~ .role-category-pill:hover, 
.jobs-pill-checkbox:not(:checked) ~ .role-category-pill:focus, 
.jobs-pill-checkbox:checked ~ .role-category-pill {
  background-color: #ffb3ff !important;
  color: black;
}

.value-category-pill {
  border-color: #ffb3ff !important;
}

.value-category-pill .pill-icon {
  color: #ffb3ff;
}

.jobs-pill-checkbox:not(:checked) ~ .value-category-pill:hover, 
.jobs-pill-checkbox:not(:checked) ~ .value-category-pill:focus, 
.jobs-pill-checkbox:checked ~ .value-category-pill {
  background-color: #ffb3ff !important;
  color: black;
}

.aggregate-value-category-pill {
  border-color: #ffff33 !important;
}

.aggregate-value-category-pill .pill-icon {
  color: #ffff33;
}

.jobs-pill-checkbox:not(:checked) ~ .aggregate-value-category-pill:hover, 
.jobs-pill-checkbox:not(:checked) ~ .aggregate-value-category-pill:focus, 
.jobs-pill-checkbox:checked ~ .aggregate-value-category-pill {
  background-color: #ffff33 !important;
  color: black;
}

.jobs-pill-checkbox:not(:checked) ~ .select-pill:hover .pill-icon, 
.jobs-pill-checkbox:not(:checked) ~ .select-pill:focus .pill-icon, 
.jobs-pill-checkbox:checked ~ .select-pill .pill-icon  {
  color: white;
}

.job-matching-header {
  padding-top: 25vh;
  text-align: center;
}

.job-matches-form {
  margin-top: 50px;
  margin-bottom: 100px;
}

.job-card-image {
  margin: auto;
  margin-left: 20px;
  margin-right: 20px;
  width: 7%;
  height: 7%;
  box-shadow: 5px 5px 5px #999;
  background-color: white;
  border-radius: 100%;
}

.job-card {
  flex-direction: row;
  /* overflow: scroll; */
  margin-right: 20px;
}

@media (max-width: 600px) {
  .job-card-image {
    width: 20%;
    height: 20%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .job-board-div {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.job-matches-form-div {
  margin-left: 10%;
  margin-right: 10%;
}

.job-tag {
  float: left;
  margin-right: 10px;
}

.job-tags-div {
  padding-top: 10px;
  border-top: 1px solid lightgrey;
}

.job-card-body {
  margin-bottom: 10px;
}

.location-svg { 
  width: 20px;
}

.jobs-pill-checkbox {
  display: none;
}

.jobs-request-again-button {
  color: white;
}

.jobs-request-again-div {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.job-board-no-results-div {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 150px;
}

.no-results-image {
  width: 100px;
}

.jobs-match-accordian {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  background: white;
  border-radius: 25px;
  box-shadow: 8px -8px #ffb3ff;
  border: 1px solid lightgrey;
}
    

@media (max-width: 600px) {
  .jobs-match-accordian {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.job-h1 {
  font-family: halcom, system-ui,sans-serif;
  line-height: 1.05;
  color: black;
  font-weight: 800;
  margin: 0;
  font-size: 48px;
}

.job-h3 {
  font-size: 2rem;
  font-family: halcom,system-ui,sans-serif;
  line-height: 1.05;
  color: black;
  font-weight: 300;
}

.job-h3-purple {
  color: #5271ff;
  font-weight: 800;
}

.job-matching-word-emphasis {
  color: #5271ff;
  font-weight: 700;
}

.job-matching-talent-prompt {
  margin-bottom: 30px;
}

.job-accordian-body {
  height: 40vh;
  overflow: scroll;
}

.balance-img {
  width:100%;
}

.vire-logo {
  display: flex;
  /* padding: 0 0 0 20px; */
  align-items: center;
}

.vire-logo-text {
  padding-left: 20px;
  color: black !important;
  font-weight: 500;
}

@media screen and (max-width: 40em) {
  .vire-logo-text {
    display: none;
  }
}

.navbar-vire {
  border-bottom: 1px solid #EAEAEA;
  padding: 0 20px 0 20px;
  font-size: 16px;
  box-shadow: 7px 7px 15px -10px #727272;
  background: #f5f5f5;
}

@media screen and (max-width: 991px) {
  .navbar-vire {
    border: none;
    padding: 20px;
  }
}

.bold-header {
  font-weight: 700;
  color: black;
}

.vire-sign-up-button {
  color: white;
  border-radius: 10rem !important;
  height: 100%;
  font-size: 1.2rem;
}

.vire-nav-button {
  margin-left: 2rem;
  font-size: 16px;
}

.vire-nav-link {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  color: black !important;
  font-weight: 500;
}

.vire-category-button {
  color: #ffffff;
  background-color: black;
  border-color: black;
  box-shadow: 2px 2px 2px #999;
  border-radius: 12px !important;
  font-size: 16px;
}

.vire-category-button:disabled {
  color: #cccccc;
  background-color: #666666;
  border-color: #666666;
  box-shadow: none;
  cursor: not-allowed;
  transform: none !important;
}

.vire-category-button:not(:disabled):hover, .vire-category-button:not(:disabled):active, .vire-category-button:not(:disabled):focus {
  color: #ffffff !important;
  background-color: black !important;
  border-color: black !important;
  transform: scale(1.1);
  outline: none !important;
  box-shadow: 2px 2px 2px #999 !important;
}

.job-matching-scale-img {
  width: 80%;
}

.vire-header-section {
  padding-top: 50px;
  padding-bottom: 100px; 
  text-align: center;
}

.vire-header-text {
  padding-top: 25px;
  align-items: center;
}

.vire-header-copy {
  padding-top: 20px;
  font-size: 1.2rem;
}

.vire-page {
  padding-top: 70px;
  padding-left: 50px;
  padding-right: 50px;
}

@media screen and (max-width: 600px) {
  .vire-header-text {
    padding-top: 25px;
  }
  
  .vire-header-copy {
    padding-right: 0px;
  }

  .vire-page {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.purple-section {
  background: linear-gradient(to bottom, #D5FF2E, #f6f6f6);
  padding: 100px 120px 100px 120px;
}

.small-purple-section {
  margin: 150px 100px;
  padding: 100px;
  border-radius: 30px;
}

@media screen and (max-width: 990px) {
  .purple-section {
    padding: 50px 50px 50px 50px;
  }

}

.vire-explained-section {
  background: #DCE3FF;
  padding-left: 100px;
  padding-right: 100px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: 100px;
  border-radius: 30px;
}

.vire-prop {
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  height: 100%;
  border-radius: 20px;
  box-shadow: 5px 5px 5px #999;
}

.vire-prop-column {
  padding-bottom: 20px;
}

.vire-prop-header {
  font-size: 1.3rem;
}

.vire-prop-copy {
  font-size: 1.1rem;
}

.vire-prop-icon {
  width: 80px;
  padding-bottom: 20px;
}

.vire-prop-content {
  padding: 20px;
}

.vire-explained-header {
  padding-bottom: 20px;
  color: black;
}

.vire-second-header-section {
  margin: 250px 0;
  text-align: center;
}

.vire-second-header-copy {
  font-size: 1.3rem;
  font-weight: 300;
}

.vire-start-section {
  /* margin-top: 150px; */
  padding-left: 100px;
  padding-right: 100px;
  margin-left: -65px;
  margin-right: -65px;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: 100px;
}

.vire-start-mission-col {
  padding-top: 50px;
  padding-bottom: 10px;
}

.vire-start-mission {
  text-align: center;
  background-color: white;
  border: 2px solid #ffb3ff;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 5px 5px 5px #999;
  margin-left: 20px;
  margin-right: 20px;
  height: 100%;
}

.vire-start-mission-icon {
  width: 100px;
}

.vire-start-mission-header {
  font-size: 1.3rem;
}

.vire-start-mission-row {
  padding-top: 10px;
  padding-bottom: 10px;
}

.vire-start-mission-link {
  color: black;
  text-decoration: none;
}

.vire-start-mission-link:hover {
  color: black;
  text-decoration: none;
}

.vire-start-mission:hover {
  background-color: #ffb3ff;
}

.vire-view-all-jobs-link {
  color: black;
}

.vire-view-all-jobs-link:hover {
  color: #ffb3ff;
}

.vire-view-all-jobs-div {
  text-align: right;
  padding-right: 30px;
}

@media screen and (max-width: 767px) {
  .vire-start-header-row {
    text-align: center;
  }

  .vire-view-all-jobs-div {
    text-align: center;
    padding-right: 0px;
  }

  .vire-explained-section {
    padding: 50px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 100px;
  }
}

.vire-email-prompt {
  margin-bottom: 100px;
  text-align: center;
}

.vire-get-staretd-copy {
  padding-right: 100px;
  font-size: 1.7rem;
  font-weight: 300;
}

.vire-footer {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.coming-soon-page {
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
}

#newsletter-signup-complete-message {
  display: none;
}

#waitlist-signup-complete-message {
  display: none;
}

.signup-complete-message {
  color: #999;
}

.whoops-page {
  margin-top: 50px;
  margin-bottom: 300px;
  text-align: center;
}

.onboarding-button {
  display: inline-block;
  border-radius: 12px;
  background-color: #FFFFFF;
  border: #EAEAEA 1px solid;
  padding: 5px 10px;
  margin: 5px;
  color: black;
}

.onboarding-button-text {
  margin-bottom: 0;
}

.onboarding-button:hover {
  background-color: #E2D6FF;
  box-shadow: 5px 5px 5px #C2C2C2;
  transform: scale(1.1);
  border: #E2D6FF 1px solid;
  color: black;
}

.onboarding-button:active {
  background-color: #C6ADFF !important;
  border: #C6ADFF 1px solid !important;
  outline: none !important;
  box-shadow: 5px 5px 5px #C2C2C2 !important;
  color: black !important;
}

.onboarding-container {
  padding: 50px 0;
  text-align: center;
}

.vire-explained-icon {
  width: 100px;
  padding-bottom: 20px;
  display: block;
}

.profile-example-video {
  width: 300px;
  border-radius: 50px;
  box-shadow: 5px 5px 5px #999;
  overflow: hidden;
  margin: 0 50px;
}

/* @media screen and (max-width: 1200px) {
  .profile-example-video {
    border-radius: 35px;
  }
} */

@media screen and (max-width: 991px) {
  .profile-example-video {
    align-self: center;
    margin: 50px 0 0 0;
  }

  .profile-example-video-col {
    display: flex;
    justify-content: center;
  }
}

.questions-section {
  padding: 0 200px 100px 200px;
}

.purple-link {
  color: #5271ff;
}

.purple-link:hover {
  color: #C6ADFF;
  text-decoration: none;
  cursor: pointer;
}
.frequent-question-accordian {
  margin: 50px 0;
}

.frequent-question-accordian-item {
  padding: 10px 0;
  border-top: 1px solid lightgrey;
}

.frequent-question-accordian-header button {
  width: 100%;
  border: none;
  background: none;
  text-align: left;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0;
  text-decoration: none;
  color: black;
}

.frequent-question-accordian-header button:focus {
  outline: none;
  color: black;
}

@media (max-width: 800px) {
  .questions-section {
    padding: 0 0 100px 0;
  }

  .job-h1 {
    font-size: 2rem;
  }

  .job-landing-header-container {
    height: 9rem;
  }
}

.load-more-button {
  float: right;
  margin: 50px 20px 0 0;
}

.candidate-img {
  height: 150px;
  margin: 0 10px 10px 10px;
  border-radius: 12px;
  border: 1px solid black;
}

.candidate-img-container {
  margin-right: -2000px;
}

.candidate-img-middle-row {
  margin-left: 50px;
}

.candidate-img-last-row {
  margin-left: 150px;
}

.vire-employer-header {
  padding: 100px 20px 50px 20px;
}

.people-on-phones-img {
  width: 200px;
  height: 200px;
  border-radius: 12px;
  margin: 0 20px 40px 0;
  border: 1px solid black;
}

@media (max-width: 600px) {
  .people-on-phones-img {
    width: 150px;
    height: 150px;
  }
}


.second-people-on-phones-img {
  margin-left: 70px;
}

.people-on-phones-div {
  display: flex;
  justify-content: center;
  margin-left: -100px;
  margin-right: -100px;
  overflow-x: auto;
  scrollbar-width: none;
}

.employers-selling-points-container {
  padding: 50px 50px 100px 50px;
  background: #EDFFA3;
  border-radius: 12px;
}

.employers-outcomes-points-container {
  padding: 50px 50px 100px 50px;
  background: #DCE3FF;
  border-radius: 12px;
}

.employers-selling-point {
  padding: 0 0 10px 0;
}

.employer-point-container {
  padding: 30px;
  border-radius: 12px;
  height: 100%;
  align-content: center;
}

.employer-point-column {
  padding: 20px;
  border-radius: 12px;
}

.employer-point-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.employer-silence-noise-section {
  padding: 0 300px;
}

.hire-smarter-section {
  background: white;
  padding: 50px;
  border-radius: 12px;
  border: 1px solid black;
}

.hire-smarter-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vire-silence-noise-container {
  margin: 150px 0 200px 0;
  text-align: center;
}

.employer-screenshot {
  width: 100%;
  border: 1px solid #eaeaea;
  background: #ffffff;
  margin: 10px;
  border-radius: 12px;
  border: 1px solid black;
}

.employer-screenshot-section {
  padding: 0 100px 100px 100px;
}

.optimization-venn-diagram {
  width: 100%;
}

.employer-get-started-div {
  text-align: center;
  margin: 200px 0 0 0;
  background: linear-gradient(45deg, #D5FF2E, #EDFFA3);
  padding: 100px 20px;
  border-radius: 24px;
}

.employer-get-started-header {
  color: black;
  font-weight: 700;
}

.employers-selling-points-row {
  margin: 50px 0;
}

@media screen and (max-width: 991px) {
  .employer-silence-noise-section {
    padding: 0 100px;
  }
  .small-purple-section {
    margin: 0 50px;
    padding: 80px;
    border-radius: 12px;
  }
}

@media screen and (max-width: 600px) {
  .employer-silence-noise-section {
    padding: 0 50px;
  }
  .employers-selling-points-container {
    padding: 0 0 100px 0;
  }
  .small-purple-section {
    margin: 0;
    padding: 40px;
    border-radius: 12px;
  }
}

.ats-logos-section {
  text-align: center;
  margin: 300px 0 350px 0;
}

.ats-logos-container {
  display: flex;
  justify-content: space-evenly;
  margin: 50px 0 0 0;
}

.employer-get-started-form {
  align-items: center;
  min-width: 400px;
}

@media (max-width: 600px) {
  .employer-get-started-form {
    align-items: center;
    min-width: 300px;
  }
}

.employer-get-started-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.employer-get-started-form-group {
  margin: 20px 0;
}

.employer-reach-out {
  margin: 40px 0 0 0;
}

#employer-submit-error {
  display: none;
}

#employer-submit-success {
  display: none;
}

.signup-error-message {
  color: #DE3C4B;
}

.footer-links {
  padding: 20px 0;
}

.testimonial-header {
  text-align: center;
}


.testimonial-photo {
  width: 200px;
}

.testimonial-photo-col {
  display: flex;
  justify-content: center;
}

.testimonial-row {
  align-items: center;
  height: 100%;
}

.testimonial-section {
  padding: 50px 0;
}


.find-your-match-header{
  color: black;
}

.testimonial-container {
  text-align: center;
  max-width: 400px;
  padding: 50px 10px 0 10px;
}

@media (max-width: 1000px) {
  .testimonial-container {
    flex: 1 1 100%;
  }
}

.testimonials-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.testimonial-photo-div {
  height: 200px;
}

.vire-framework-header {
  padding: 100px 20px 50px 20px;
  text-align: center;
}

.vire-framework-container {
  padding: 30px 50px;
  display: flex;
  align-items: center;
}

.vire-framework-section {
  padding: 0 0 300px 0;
}

.vire-framework-item {
  padding: 30px 40px;
  border-radius: 12px;
  box-shadow: 5px 5px 5px #c2c2c2;
  background: #ffffff;
  margin: 10px;
  height: 100%;
}

.vire-jobseeker-framework-item {
  padding: 30px 40px;
  border-radius: 12px;
  box-shadow: 5px 5px 5px #c2c2c2;
  background: linear-gradient(45deg, #D5FF2E, #EDFFA3);
  margin: 10px;
  height: 100%;
}

.vire-jobseeker-framework-item:hover {
  transform: scale(1.1);
}

.vire-framework-item:hover {
  transform: scale(1.1);
}

.off-white-background {
  background: #f6f6f6;
}

.vire-framework-icon {
  text-align: center;
  margin: 30px 0;
}

.vire-framework-item-text {
  color: #727272;
  margin: 20px 0 0 0;
}

.vire-framework-col {
  margin: 20px 0;
}

.value-prop-container {
  padding: 30px 0;
}

.unique-process-header {
  text-align: center;
  padding: 50px 0 0 0;
}

.unique-process-subheader {
  text-align: center;
  padding: 0 200px;
}

.vire-solution-item {
  padding: 50px;
  border-radius: 12px;
  box-shadow: 5px 5px 5px #c2c2c2;
  background: #DCE3FF;
  margin: 10px;
  height: 100%;
}

.vire-solution-item:hover {
  transform: scale(1.1);
}

.vire-solution-header {
  color: #5271ff;
  min-height: 80px;
}

.vire-solution-features-header {
  padding: 20px 0;
  font-size: 1.2rem;
}

.no-bullets {
  list-style-type: none;
  padding-left: 0;
}

.solution-list {
  padding: 0 0 10px 10px;
}

.with-vire-section {
  border: 1px solid black;
  border-radius: 12px;
  color: black;
  background: #D5FF2E;
  font-weight: 700;
}